export const LEARNING_GOAL_OPTIONS = {
  LEARNING_GOAL_LAND_NEW_JOB: 'LEARNING_GOAL_LAND_NEW_JOB',
  LEARNING_GOAL_CHANGE_CAREER: 'LEARNING_GOAL_CHANGE_CAREER',
  LEARNING_GOAL_IMPROVE_SKILLS: 'LEARNING_GOAL_IMPROVE_SKILLS',
  LEARNING_GOAL_PERSONAL_INTEREST: 'LEARNING_GOAL_PERSONAL_INTEREST',
} as const;

export type LEARNING_GOAL_OPTIONS_TYPE = (typeof LEARNING_GOAL_OPTIONS)[keyof typeof LEARNING_GOAL_OPTIONS];

export const EDUCATION_LEVEL_OPTIONS = {
  EDUCATION_LEVEL_LESS_THAN_HIGH_SCHOOL_DIPLOMA: 'EDUCATION_LEVEL_LESS_THAN_HIGH_SCHOOL_DIPLOMA',
  EDUCATION_LEVEL_HIGH_SCHOOL_DIPLOMA: 'EDUCATION_LEVEL_HIGH_SCHOOL_DIPLOMA',
  EDUCATION_LEVEL_COLLEGE_NO_DEGREE: 'EDUCATION_LEVEL_COLLEGE_NO_DEGREE',
  EDUCATION_LEVEL_ASSOCIATE_DEGREE: 'EDUCATION_LEVEL_ASSOCIATE_DEGREE',
  EDUCATION_LEVEL_BACHELOR_DEGREE: 'EDUCATION_LEVEL_BACHELOR_DEGREE',
  EDUCATION_LEVEL_MASTERS_DEGREE: 'EDUCATION_LEVEL_MASTERS_DEGREE',
  EDUCATION_LEVEL_PROFESSIONAL_DEGREE: 'EDUCATION_LEVEL_PROFESSIONAL_DEGREE',
  EDUCATION_LEVEL_DOCTORATE_DEGREE: 'EDUCATION_LEVEL_DOCTORATE_DEGREE',
} as const;

export type EDUCATION_LEVEL_OPTIONS_TYPE = (typeof EDUCATION_LEVEL_OPTIONS)[keyof typeof EDUCATION_LEVEL_OPTIONS];

export const ROLE_OPTIONS_TYPE = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
} as const;

export const PATHWAYS_ONBOARDING_EVENT_NAME = 'onboarding_pathways_multi_page';

export const ONBOARDING_BASE_URL = '/onboarding-2022';

export const LOGGED_OUT_ONBOARDING_LOCAL_STORAGE_KEY = 'LOGGED_OUT_ONBOARDING_ANSWERS';
