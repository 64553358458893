/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import GrowthAcquisition from 'bundles/epic/data/defaults/GrowthAcquisition.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  useEnterpriseCheckboxReCaptchaLogin: boolean;
  useEnterpriseCheckboxReCaptchaSignup: boolean;
  magicLinksEnabled: boolean;
  enableArticlesByline: boolean;
  marketingConsentModalRestrictedEnterprises: any;
  shouldEnableArkoseBotManager: boolean;
  degreesExcludedFromShowingEOI: any;
  useSubscriptionsForwardDiscoverySessionBased: 'control' | 'variant1' | 'variant2' | 'variant3';
  useSubscriptionsForwardDiscoveryUserBased: 'control' | 'variant1' | 'variant2' | 'variant3';
};

const NAMESPACE = 'GrowthAcquisition';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([GrowthAcquisition as $TSFixMe]);

const GrowthAcquisitionEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default GrowthAcquisitionEpicClient;
