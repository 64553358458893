import * as React from 'react';

import { compose } from 'recompose';

import withFetchLazyImages from 'bundles/page/components/shared/utils/withFetchLazyImages';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import GlobalStyleResets from 'bundles/unified-description-page-common/components/GlobalStyleResets';
import withMarketingConsent from 'bundles/user-consent/components/withMarketingConsent';

import 'css!bundles/unified-description-page-common/__styles__/index';

type PropsFromCaller = {
  children?: JSX.Element;
};

type PropsToComponent = PropsFromCaller;

const ConsumerPageWrapper = ({ children }: PropsToComponent) => {
  return (
    <React.Fragment>
      <GlobalStyleResets />
      <UnifiedAppCheckProvider>{children}</UnifiedAppCheckProvider>
    </React.Fragment>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  withMarketingConsent,
  withFetchLazyImages
)(ConsumerPageWrapper);
